/* eslint no-use-before-define: 0 */
import { Select } from "antd";
import Button from "antd/es/button";
import DatePicker from "antd/es/date-picker";
import Dropdown from "antd/es/dropdown";
import Input from "antd/es/input";
import Menu from "antd/es/menu";
import Space from "antd/es/space";
import Table from "antd/es/table";
import React, { useContext, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import MDAsContext from "../../contexts/index";
import { GetLicenses, GetModules, PostAssignModule } from "../../utility/fetch";
import Notification from "../../utility/notification";
import Greeting from "../layouts/Greeting";
import ServiceWidget from "../layouts/ServiceWidget";
import AssignModal from "../modals/AssignModal";

const { RangePicker } = DatePicker;
const { Option } = Select;

const { TextArea } = Input;

const Modules = ({ history }) => {
  const [title, setTitle] = useState("");
  const [receivedMemos, setReceivedMemos] = useState(0);
  const [searchText, setSearchText] = useState();
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleRequest, setSingleRequest] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAssignVisible, setModalAssignVisible] = useState(false);
  const [modalUpdateVisible, setModalUpdateVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [details, setDetails] = useState("");
  const [activeMemo, setActiveMemo] = useState("pending");
  const [totalElement, setTotalElement] = useState();
  const [limit, setLimit] = useState(20);
  const [current, setCurrent] = useState(1);
  const { mdas } = useContext(MDAsContext);
  const [assignId, setAssignId] = useState([]);
  const [licenses, setLicenses] = useState([]);

  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setCurrent(current);
  };

  useEffect(() => {
    fetchModules();
    fetchLicenses();
  }, []);

  const fetchLicenses = async () => {
    // setLoading(true);
    try {
      const result = await GetLicenses();
      if (result?.data?.code === 1) {
        setLicenses(result?.data?.data);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    // setLoading(false);
  };

  const fetchModules = async () => {
    // setLoading(true);
    try {
      const result = await GetModules();
      if (result?.data?.code === 1) {
        setModules(result?.data?.data);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    // setLoading(false);
  };

  const AssignModule = async (payload) => {
    if (assignId.length > 0) {
      setLoading(true);
      try {
        payload.moduleIds = assignId;
        payload.isActive = true;
        const result = await PostAssignModule(payload);
        if (result?.data?.code === 1) {
          Notification({ type: "success", message: result.data.message });
        } else {
          Notification({ type: "warning", message: result.data.message ?? "An error occured" });
        }
      } catch (ex) {
        const error = ex.Response;
        Notification({ type: "warning", message: error ?? "An error occured" });
      }
      setAssignId([]);
      setModalAssignVisible(false);
      setLoading(false);
    } else {
      Notification({
        type: "warning",
        message: "Please select a module to assign",
      });
    }
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setAssignId([record.id]);
          setModalAssignVisible(true);
        }}
      >
        <a rel="noopener noreferrer" href="#">
          Assign
        </a>
      </Menu.Item>
    </Menu>
  );

  const modulesColums = [
    {
      title: <strong className="family">Name</strong>,
      dataIndex: "name",
      width: "20%",
      editable: false,
    },
    {
      title: <strong className="family">Active</strong>,
      // dataIndex: "requestDetails",
      render: (item) => (
        <p
          style={{ cursor: "pointer" }}
          // onClick={() => {
          //   setDetails(item?.requestDetails);
          //   setModalVisible(true);
          // }}
        >
          {item?.active ? "Yes" : "No"}
        </p>
      ),
      width: "20%",
      align: "center",
    },
    {
      title: <strong className="family">Date Created</strong>,
      dataIndex: "createdAt",
      width: "20%",
    },
    {
      title: <strong className="family">Last Updated</strong>,
      dataIndex: "modifiedAt",
      width: "20%",
    },
    {
      title: <strong className="family">Action</strong>,
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (text, record) => (
        <Space direction="vertical">
          <Space wrap>
            <Dropdown
              onClick={(event) => event.stopPropagation()}
              overlay={menu(record)}
              trigger={["click"]}
              placement="bottom"
            >
              <Button style={{ backgroundColor: "#1C811C", color: "white" }}>
                Select
              </Button>
            </Dropdown>
          </Space>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Modules";
    setTitle("Modules");
  }, []);

  const handleChange = (value) => {};

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setAssignId(selectedRowKeys);
    },
  };

  return (
    <div className="w-100">
      {loading ? (
        <div>
          <Loader type="ThreeDots" color="green" height={100} width={100} />
        </div>
      ) : (
        <>
          <Greeting />

          <div className="flex space-between m-b-20">
            <Button
              onClick={() => setModalAssignVisible(true)}
              style={{ backgroundColor: "#1C811C", color: "white" }}
            >
              Assign
            </Button>
          </div>
          <div className="flex space-between w-100 primary-color p-15 ">
            <strong className="family">Licenses</strong>
          </div>
          <div className="w-100">
            <Table
              bordered
              scroll={{ x: "80vw"}}
              pagination={false}
              dataSource={modules}
              columns={modulesColums}
              rowClassName="editable-row"
              rowSelection={{
                ...rowSelection,
              }}
              rowKey="id"
            />
          </div>

          <ServiceWidget />
        </>
      )}

      <AssignModal
        title="Assign"
        onFinish={AssignModule}
        modalVisible={modalAssignVisible}
        setModalVisible={setModalAssignVisible}
        mdas={mdas}
        licenses={licenses}
      />
    </div>
  );
};

export default Modules;
