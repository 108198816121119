import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import { MdNotifications } from "react-icons/md";
import { TiArrowSortedDown } from "react-icons/ti";
import Cookies from "js-cookie";

import UserAvater from "../../assets/images/user-avater.png";
import HeaderSearch from "../inputs/HeaderSearch";

import { formatFileUrl } from "../../utility/general";

const Header = ({ history, details = {}, navList = [] }) => {
  const [userName, setUserName] = useState("");
  const [userPix, setUserPix] = useState("");
  const [role, setRole] = useState("");
  const [imgHasError, setImgHasError] = useState(false);
  // const userName = `${localStorage.get("_FirstName")} ${localStorage.get("_LastName")}`;
  // const profilePicture = `${localStorage.get("profilePicture")}`;
  // const role = `${localStorage.get("_Role")}`;

  useEffect(() => {
    !imgHasError && setUserPix(formatFileUrl(details.userProfilePicture));
    setUserName(
      `${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}`
    );
    setUserPix(`${localStorage.getItem("profilePicture")}`);
    setRole(`${localStorage.getItem("role")}`);
  }, [
    details,
    localStorage.getItem("role"),
    localStorage.getItem("profilePicture"),
    localStorage.getItem("firstName"),
  ]);

  const handleImgError = () => {
    setUserPix("");
    setImgHasError(true);
  };

  return (
    <header>
      <div className="content header-content space-between flex-v-center">
        <div className="header-left">
          {/* <HeaderSearch placeholder="Search transactions or invoices" /> */}
        </div>
        <div className="header-right flex flex-v-center">
          <div className="right-item">
            <a
              role="presentation"
              href="/portal-dashboard/profile"
              onClick={() => {}}
              className="user-avater-details flex flex-v-center pointer"
              style={{ color: "white", textDecoration: "none" }}
            >
              <div className="m-r-5 right-text" style={{ paddingTop: "3px" }}>
                <p className="name">{userName}</p>
                <p className="role">{role || "Officer 1"}</p>
              </div>
              <div className="flex">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = { UserAvater };
                  }}
                  src={userPix || UserAvater}
                  alt="user avater"
                />
              </div>
              <div className="flex arrow">
                <TiArrowSortedDown />
              </div>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
