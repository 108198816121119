import React, { useState } from "react";
import { AiTwotonePhone } from "react-icons/ai";
import { BiMessageDetail } from "react-icons/bi";
import { IoMdArrowDropup } from "react-icons/io";
// import { IoMdArrowDropdown } from 'react-icons/md';
import ServiceManagement from "../pages/ServiceManagement";

const ServiceWidget = () => {
  const [show, setshow] = useState(false);
  const [open, setOpen] = useState(false);
  const [subCategory, setSubCategory] = useState(2);

  const offFunc = () => {
    setOpen(false);
  };
  return (
    <div
      className="widget"
      style={{ width: "", backgroundColor: "transparent" }}
    >
      {show && (
        <div>
          <div
            className="pointer w-100 p-10 widget-transition2"
            onClick={() => {
              setshow(!show);
            }}
            style={{
              padding: "10px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <p>Contact Us</p> */}
            {/* <IoMdArrowDropdown style={{fontSize:"20px"}}/> */}
          </div>
          <div className=" p-10 bg-white">
            {/* <div className=' p-10 ' style={{textAlign:"center"}}> 
              
              Via whatsApp
              <div style={{textAlign:"center", display:"flex",  alignItems:"center", justifyContent:"center", padding:"10px 0", borderBottom:"2px solid #25d366"}}>
        
        <IoLogoWhatsapp style={{color:"#25d366",fontSize:"28px", marginRight:"10px"}}/> 
      <div>
        <p>
          <a className='bold messageUs pointer' href='https://web.whatsapp.com/send?phone=2348098896767&text=Hello%20Team%2C%20I%20am%20reaching%20out%20because%20I%20have%20technical%20issues%20on%20EdoGOV%20Platform.'>
        +234 809 889 6767
        </a>
        </p>
      <p>
        <a className='bold messageUs pointer' href='https://web.whatsapp.com/send?phone=2348077879988&text=Hello%20Team%2C%20I%20am%20reaching%20out%20because%20I%20have%20technical%20issues%20on%20EdoGOV%20Platform'>
      +234 807 787 9988
      </a>
      </p>
      </div>
      </div>
              </div> */}
            <div className=" p-10 " style={{ textAlign: "center" }}>
              <div
                className="flex-align"
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="bold">
                  <AiTwotonePhone
                    style={{
                      color: "#25d366",
                      fontSize: "28px",
                      marginRight: "10px",
                    }}
                  />
                </span>
                <p style={{ marginTop: "8px" }}>Via telephone</p>
              </div>
              <div
                className=" flex"
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 0",
                  borderBottom: "2px solid #25d366",
                }}
              >
                <div>
                  <p className="bold  messageUs ">+234 706 857 7182</p>
                  <p className="bold  messageUs ">+234 703 348 4941</p>
                  <p className="bold messageUs  ">+234 706 739 9959</p>
                  <p className="bold messageUs  ">+234 904 486 6498</p>
                </div>

                <div className="m-l-10">
                  <p className="bold messageUs  ">+234 813 155 4637</p>
                  <p className="bold messageUs  ">+234 701 830 2984</p>
                  <p className="bold messageUs  ">+234 817 973 5336</p>
                  <p className="bold messageUs  ">+234 816 891 8671</p>
                </div>
              </div>
            </div>
            {/* <p className='bold' style={{textAlign:'center'}}>OR </p> */}
            <div
              className=" p-10 pointer "
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              <p className="m-r-10 messageUs"> Message Us</p>
              <BiMessageDetail style={{ color: "#25d366", fontSize: "28px" }} />
            </div>
          </div>
        </div>
      )}

      {!show && (
        <div>
          {/* <div className='pointer w-100 p-10 widget-transition2' onClick={()=>{setshow(!show)}} style={{padding:"10px",  color:"white",display:'flex', justifyContent:"space-between", alignItems:"center" }}>
           
          
          </div> */}
          <div
            className="pointer widget-transition"
            onClick={() => {
              setshow(!show);
            }}
            style={{
              padding: "10px",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Contact Us</p>
            <IoMdArrowDropup style={{ fontSize: "20px" }} />
          </div>
        </div>
      )}
      {open && <ServiceManagement offFunc={offFunc} />}
    </div>
  );
};

export default ServiceWidget;
