import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import Greeting from "../layouts/Greeting";
import Page6Cards from "../layouts/Page6Cards";
import Checkbox from "antd/es/checkbox";
import Table from "antd/es/table";
import Space from "antd/es/space";
import {
  GetLicensesSettings,
  PostLicenseSettings,
  GetLicenses,
  PatchLicenseSettings,
  DeleteLicenseSettings,
  GetMdaUsers,
  GetLicensesUsers,
  GetSingleLicensesUsers,
} from "../../utility/fetch";
import Notification from "../../utility/notification";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Select from "antd/es/select";
import MDAsContext from "../../contexts/index";
import CreateLicenseSettingsModal from "../modals/CreateLicenseSettingsModal";

const { Option } = Select;

const { TextArea } = Input;

const Users = () => {
  const [title, setTitle] = useState("");
  const [moduleCount, setModuleCount] = useState([]);
  const [licenseSettings, setLicenseSettings] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalUpdateVisible, setModalUpdateVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { mdas, modules } = useContext(MDAsContext);
  const [id, setId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [users, setUsers] = useState([]);
  const [singleUser, setSingleUser] = useState([]);

  const history = useHistory();

  const fetchLicenseUsers = async () => {
    const result = await GetLicensesUsers();
    const userList = result?.data?.data;

    setUsers(userList);
  };

  const fetchSingleLicenseUser = async (id) => {
    const result = await GetSingleLicensesUsers(id);
    const user = result?.data?.data;

    setSingleUser(user);
  };

  useEffect(() => {
    fetchLicenseUsers();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Users";
    setTitle("Users");
  }, []);

  const usersColumns = [
    {
      title: <strong className="family">User Name</strong>,
      dataIndex: "userFullName",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Number Of Modules</strong>,
      dataIndex: "moduleCount",
      width: "5%",
      editable: false,
    },
    {
      title: <strong className="family">License Name</strong>,
      dataIndex: "license",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">MDA</strong>,
      dataIndex: "mdaName",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Module</strong>,
      dataIndex: "module",
      width: "10%",
      align: "center",
    },
    {
      title: <strong className="family">Date Created</strong>,
      dataIndex: "dateCreated",
      width: "15%",
    },
    {
      title: <strong className="family">Action</strong>,
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (text, record) => (
        <Space direction="vertical">
          <Space wrap>
            <Button
              onClick={() => fetchSingleLicenseUser(record?.userId)}
              style={{ backgroundColor: "#1C811C", color: "white" }}
            >
              View
            </Button>
          </Space>
        </Space>
      ),
    },
  ];

  const handleChange = async (value) => {
    var result = await GetMdaUsers(value);
    const userList = result?.data?.data;

    setUsers(userList);
  };

  const RedirectToModuleLink = async (payload) => {
    history.push(`/licensing/user/modules?id=${payload?.userId}`);
  };

  return (
    <div className="w-100">
      {loading ? (
        <div>
          <Loader type="ThreeDots" color="green" height={100} width={100} />
        </div>
      ) : (
        <>
          {/* <div className="flex space-between m-b-20">
            <div className="flex">
              <div className={`search-input`}>
                <Select
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  onChange={handleChange}
                >
                  {mdas.map((item) => (
                    <Option
                      key={item.value}
                      className="customized_input"
                      value={item.value}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div> */}

          <div className="flex space-between w-100 primary-color p-15 ">
            <strong className="family">Users</strong>
          </div>

          <div className="w-100">
            <Table
              bordered
              dataSource={users}
              scroll={{ x: "80vw"}}
              columns={usersColumns}
              rowClassName="editable-row"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    RedirectToModuleLink(record);
                  },
                };
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Users;
