/* eslint-disable react/jsx-props-no-spreading */
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: PureComponent, ...rest }) => {
  const il = { il: true }; // isLoggedin
  useEffect(() => {
    let Access_token = localStorage.getItem("token");
    let token = jwtDecode(Access_token);
    if (Date.now() >= token?.exp * 1000) {
      window.location.href = "/";
      return;
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        il && il.il ? (
          <PureComponent {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
