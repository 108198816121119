import React, { useEffect } from "react";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import InputNumber from "antd/es/input-number";

const { TextArea } = Input;

const CreateLicenseModal = ({
  title,
  onFinish,
  modalVisible,
  setModalVisible,
  description,
  moduleCount,
}) => {
  const [form] = Form.useForm();
  const onComplete = (value) => {
    form.resetFields();
    onFinish(value);
  };

  useEffect(() => {
    form.setFieldsValue({
      description,
      moduleCount,
    });
  }, [description]);
  return (
    <Modal
      title={title}
      centered
      visible={modalVisible}
      onOk={() => setModalVisible(false)}
      onCancel={() => {
        form.resetFields();
        setModalVisible(false);
      }}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        // initialValues={{ description, moduleCount }}
        onFinish={onComplete}
      >
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="moduleCount"
          label="Count"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} min={1} />
        </Form.Item>

        <Form.Item>
          <Button
            style={{
              backgroundColor: "#1C811C",
              color: "white",
              display: "block",
              width: "100%",
            }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateLicenseModal;
