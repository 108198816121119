import React, { useState, useEffect } from "react";
import PortalRoutes from "../routes/PortalRoutes";
import Sidebar from "./layouts/Sidebar";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import MDAsContext from "../contexts";
import { GetMdas, GetModules } from "../utility/fetch";

function UserPortal(props) {
  const { history } = props;
  const [mdas, setMdas] = useState([]);
  const [modules, setModules] = useState([]);

  const getMdas = async () => {
    // setLoading(true);
    try {
      const result = await GetMdas();
      if (result?.data?.code === 1) {
        const roleList = result?.data?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setMdas(roleList);
      }
    } catch (ex) {}
    // setLoading(false);
  };

  const getModules = async () => {
    // setLoading(true);
    try {
      const result = await GetModules();
      if (result?.data?.code === 1) {
        console.log(result);
        const moduleList = result?.data?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        setModules(moduleList);
        console.log(moduleList);
      }
    } catch (ex) {}
    // setLoading(false);
  };

  useEffect(() => {
    getMdas();
    getModules();
  }, []);

  /**
   * @ useEffect function
   * On Portal load,
   * Getting userId from token
   * Getting sidebar modules for that user
   */

  return (
    <MDAsContext.Provider value={{ mdas, modules }}>
      <div className="container">
        <Sidebar history={history} />
        <div className="portal">
          <Header />
          <div className="content main-content">
            <PortalRoutes />
          </div>
          <Footer />
        </div>
      </div>
    </MDAsContext.Provider>
  );
}

export default UserPortal;
