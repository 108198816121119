import React from "react";
import Cookies from "js-cookie";

const Greeting = () => {
  const yes = 20;
  return (
    <div className="w-100 flex flex-v-center space-between m-b-20">
      <div>
        <h5 className="m-b-3">Good Day</h5>
        <h3 className="m-b-3">
          {`${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}` ||
            "Osagie Osaigbovo"}
        </h3>
        <h5>{localStorage.getItem("role") || "Officer 1, Ministry of Education"}</h5>
      </div>
    </div>
  );
};

export default Greeting;
