/* eslint no-use-before-define: 0 */
import React, { useEffect, useState, useContext } from "react";
import Table from "antd/es/table";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Radio from "antd/es/radio";
import DatePicker from "antd/es/date-picker";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Input from "antd/es/input";
import moment from "moment";
import Loader from "react-loader-spinner";
import Cookies from "js-cookie";
import Page6Cards from "../layouts/Page6Cards";
import SearchInput from "../inputs/SearchInput";
import Greeting from "../layouts/Greeting";
import ServiceWidget from "../layouts/ServiceWidget";
import {
  GetLicensesReport,
  GetLicensesAssigned,
  GetLicenses,
  GetLicensesUsers,
  PostLicense,
  PostAssignLicense,
  PatchLicense,
  deleteLicense,
  getById,
} from "../../utility/fetch";
import Notification from "../../utility/notification";
import { moduleNameData } from "../columns/requestLogColumn";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination, Select } from "antd";
import parse from "html-react-parser";
import CreateLicenseModal from "../modals/CreateLicenseModal";
import MDAsContext from "../../contexts/index";
import AssignModal from "../modals/AssignModal";

const { RangePicker } = DatePicker;
const { Option } = Select;

const { TextArea } = Input;

const Dashboard = ({ history }) => {
  const [title, setTitle] = useState("");
  const [receivedMemos, setReceivedMemos] = useState(0);
  const [licenses, setLicenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAssignVisible, setModalAssignVisible] = useState(false);
  const [modalUpdateVisible, setModalUpdateVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [activeMemo, setActiveMemo] = useState("pending");
  const [report, setReport] = useState([]);
  const { mdas } = useContext(MDAsContext);
  const [id, setId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [assignId, setAssignId] = useState("");
  const [reportId, setReportId] = useState();
  const [description, setDescription] = useState("");
  const [moduleCount, setModuleCount] = useState("");

  useEffect(() => {
    fetchLicensesUsers();
    fetchLicenseReport();
  }, []);

  useEffect(() => {
    if (reportId) {
      fetchLicenseReportId();
    }
  }, [reportId]);

  const fetchLicenseReportId = async () => {
    try {
      const result = await GetLicensesAssigned(reportId);

      if (result?.data?.code === 1) {
        setLicenses(result?.data?.data);
      }
    } catch (ex) {}
  };

  const fetchLicenseReport = async () => {
    try {
      const result = await GetLicensesReport();
      if (result?.data?.code === 1) {
        setReport(result?.data?.data);
        setReportId(result?.data?.data?.[0]?.id);
      }
    } catch (ex) {}
  };

  const fetchLicensesUsers = async () => {
    // setLoading(true);
    try {
      const result = await GetLicensesUsers();
      if (result?.data?.code === 1) {
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    // setLoading(false);
  };

  const fetchLicenses = async () => {
    // setLoading(true);
    try {
      const result = await GetLicenses();
      if (result?.data?.code === 1) {
        setLicenses(result?.data?.data);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    // setLoading(false);
  };

  const fetchSingleLicenses = async (id) => {
    // setLoading(true);
    try {
      const result = await getById(id);
      if (result?.data?.code === 1) {
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    // setLoading(false);
  };

  const CreateLicense = async (payload) => {
    setLoading(true);
    try {
      const result = await PostLicense(payload);
      if (result?.data?.code === 1) {
        setModalVisible(false);
        Notification({ type: "success", message: result.data.message });
        setModuleCount("");
        setDescription("");
        setId("");
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  const AssignLicense = async (payload) => {
    setLoading(true);
    try {
      payload.licenseId = assignId;
      const result = await PostAssignLicense(payload);
      if (result?.data?.code === 1) {
        setModalVisible(false);
        Notification({ type: "success", message: result.data.message });
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  const UpdateLicense = async (payload) => {
    setLoading(true);
    try {
      payload.id = id;
      const result = await PatchLicense(payload);
      if (result?.data?.code === 1) {
        setModalUpdateVisible(false);
        Notification({ type: "success", message: result.data.message });
        setModuleCount("");
        setDescription("");
        setId("");
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  const RemoveLicense = async () => {
    setLoading(true);
    try {
      const result = await deleteLicense(deleteId);
      if (result?.data?.code === 1) {
        setDeleteModalVisible(false);
        Notification({ type: "success", message: result.data.message });
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      setModalUpdateVisible(true);
    }
  }, [id]);

  useEffect(() => {
    if (deleteId) {
      setDeleteModalVisible(true);
    }
  }, [deleteId]);

  const menu = (record) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setId(record.id);
          setDescription(record.description);
          setModuleCount(record.moduleCount);
        }}
      >
        <a rel="noopener noreferrer" href="#">
          Edit
        </a>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setDeleteId(record.id);
        }}
      >
        <a rel="noopener noreferrer" href="#">
          Delete
        </a>
      </Menu.Item>
    </Menu>
  );

  const licensesColums = [
    {
      title: <strong className="family">User Name</strong>,
      dataIndex: "userFullName",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Number Of Modules</strong>,
      dataIndex: "moduleCount",
      width: "5%",
      editable: false,
    },
    {
      title: <strong className="family">License Name</strong>,
      dataIndex: "license",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">MDA</strong>,
      dataIndex: "mdaName",
      width: "10%",
      editable: false,
    },
    // {
    //   title: <strong className="family">Module</strong>,
    //   dataIndex: "module",
    //   width: "10%",
    //   align: "center",
    // },
    {
      title: <strong className="family">Date Created</strong>,
      dataIndex: "dateCreated",
      width: "15%",
    },
    // {
    //   title: <strong className="family">Action</strong>,
    //   dataIndex: "action",
    //   key: "action",
    //   width: "5%",
    //   render: (text, record) => (
    //     <Space direction="vertical">
    //       <Space wrap>
    //         <Dropdown
    //           onClick={(event) => event.stopPropagation()}
    //           overlay={menu(record)}
    //           trigger={["click"]}
    //           placement="bottom"
    //         >
    //           <Button style={{ backgroundColor: "#1C811C", color: "white" }}>
    //             Select
    //           </Button>
    //         </Dropdown>
    //       </Space>
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Dashboard";
    setTitle("Dashboard");
  }, []);

  const handleChange = (value) => {};

  return (
    <div className="w-100">
      {loading ? (
        <div>
          <Loader type="ThreeDots" color="green" height={100} width={100} />
        </div>
      ) : (
        <>
          <Greeting />
          <div className="flex space-between w-100 m-b-50">
            {report.map((m) => (
              <Page6Cards
                cardTitle={m.license}
                count={m.noOfUsersAssigned}
                onClick={() => setReportId(m.id)}
                isActive={m.id == reportId}
              />
            ))}
          </div>

          {/* <div className="flex space-between m-b-20">

            <Button
              onClick={() => setModalVisible(true)}
              style={{ backgroundColor: "#1C811C", color: "white" }}
            >
              Create License
            </Button>
          </div> */}
          <div className="flex space-between w-100 primary-color p-15 ">
            <strong className="family">Licenses</strong>
          </div>
          <div className="w-100">
            <Table
              bordered
              pagination={false}
              scroll={{ x: "80vw"}}
              dataSource={licenses}
              columns={licensesColums}
              rowClassName="editable-row"
            />
          </div>

          <ServiceWidget />
        </>
      )}

      <CreateLicenseModal
        title="Create License"
        onFinish={CreateLicense}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        description={description}
        moduleCount={moduleCount}
      />

      <CreateLicenseModal
        title="Update License"
        onFinish={UpdateLicense}
        modalVisible={modalUpdateVisible}
        setModalVisible={setModalUpdateVisible}
        description={description}
        moduleCount={moduleCount}
        setDescription={setDescription}
        setModuleCount={setModuleCount}
      />

      <Modal
        title="Update License"
        centered
        visible={deleteModalVisible}
        onOk={() => RemoveLicense()}
        onCancel={() => setDeleteModalVisible(false)}
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
    </div>
  );
};

export default Dashboard;
