import React from "react";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Select from "antd/es/select";

const { TextArea } = Input;
const { Option } = Select;

const CreateLicenseSettingsModal = ({
  title,
  onFinish,
  modalVisible,
  setModalVisible,
  modules,
  licenses,
}) => {
  return (
    <Modal
      title={title}
      centered
      visible={modalVisible}
      onOk={() => setModalVisible(false)}
      onCancel={() => setModalVisible(false)}
      footer={null}
    >
      <Form
        //  form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="moduleId"
          label="Module"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Please select"
            // onChange={handleChange}
          >
            {modules.map((item) => (
              <Option
                key={item.value}
                className="customized_input"
                value={item.value}
              >
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="licenseId"
          label="License"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Please select"
            // onChange={handleChange}
          >
            {licenses.map((item) => (
              <Option
                key={item.id}
                className="customized_input"
                value={item.id}
              >
                {item.description}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            style={{
              backgroundColor: "#1C811C",
              color: "white",
              display: "block",
              width: "100%",
            }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateLicenseSettingsModal;
