export const extractURLQueries = (url) => {
  const urlSearchParams = new URLSearchParams(url);
  return Object.fromEntries(urlSearchParams.entries());
};

export const convertToURLQuery = (obj) => {
  let query = '?';
  Object.keys(obj).forEach(
    (key) =>
      (query +=
        obj[key] === '' || obj[key] === undefined ? '' : `${key}=${obj[key]}&`),
  );
  // get rid of the last &
  return query.substring(0, query.length - 1);
};
