import React, { useState } from "react";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Select from "antd/es/select";
import { GetMdaUsers } from "../../utility/fetch";

const { TextArea } = Input;
const { Option } = Select;

const AssignModal = ({
  title,
  onFinish,
  modalVisible,
  setModalVisible,
  mdas,
  licenses,
}) => {
  const [users, setUsers] = useState([]);

  const handleChange = async (value) => {
    var result = await GetMdaUsers(value);
    const userList = result?.data?.data.map((item) => ({
      name: `${item.fullName}`,
      value: item.id,
    }));
    setUsers(userList);
  };

  return (
    <Modal
      title={title}
      centered
      visible={modalVisible}
      onOk={() => setModalVisible(false)}
      onCancel={() => setModalVisible(false)}
      footer={null}
    >
      <Form
        //  form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="mda"
          label="MDAs"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            style={{
              width: "100%",
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Please select"
            onChange={handleChange}
          >
            {mdas.map((item) => (
              <Option
                key={item.value}
                className="customized_input"
                value={item.value}
              >
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="userIds"
          label="User"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            style={{
              width: "100%",
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Please select"
            mode="multiple"
          >
            {users.map((item) => (
              <Option
                key={item.value}
                className="customized_input"
                value={item.value}
              >
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            style={{
              backgroundColor: "#1C811C",
              color: "white",
              display: "block",
              width: "100%",
            }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignModal;
