import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { RiLogoutCircleLine, RiBarChartFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { BiEnvelope } from "react-icons/bi";
import { BiLogOut } from "react-icons/bi";
import { logout } from "../../utility/auth";
import { IoIosArrowForward, IoIosApps } from "react-icons/io";

import EdsgLogo from "../../assets/images/edsg-logo-250.png";

const Sidebar = ({ history, menuList, loadingMenu }) => {
  const {
    location: { pathname },
  } = history;

  const handleLogout = async () => {
    await logout();
  };

  return (
    <nav className="page-sidebar">
      <div className="sidebar-header">
        <img src={EdsgLogo} alt="logo" className="brand" width="150" />
      </div>

      <div className="sidebar-menu">
        <ul className="menu-items">
          <li>
            <RiBarChartFill className="icon" />
            <Link
              to="/licensing"
              role="presentation"
              className="has-sub-menu pointer"
            >
              <span className="title">Dashboard</span>
            </Link>
          </li>

          <li>
            <RiBarChartFill className="icon" />
            <Link
              to="/licensing/modules"
              role="presentation"
              className="has-sub-menu pointer"
            >
              <span className="title">Modules</span>
            </Link>
          </li>

          <li>
            <BiEnvelope className="icon" />
            <Link
              to="/licensing/users"
              role="presentation"
              className="has-sub-menu pointer"
            >
              <span className="title">Users</span>
            </Link>
          </li>

          <li>
            <BiEnvelope className="icon" />
            <Link
              to="/licensing/licenses"
              role="presentation"
              className="has-sub-menu pointer"
            >
              <span className="title">Licenses</span>
            </Link>
          </li>

          <li>
            <BiLogOut className="icon" />
            <Link
              to="#"
              onClick={handleLogout}
              role="presentation"
              className="has-sub-menu pointer"
            >
              <span className="title">Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
