/* eslint no-use-before-define: 0 */
import React, { useEffect, useState, useContext } from "react";
import Table from "antd/es/table";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Radio from "antd/es/radio";
import DatePicker from "antd/es/date-picker";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Input from "antd/es/input";
import moment from "moment";
import Loader from "react-loader-spinner";
import Cookies from "js-cookie";
import Page6Cards from "../layouts/Page6Cards";
import SearchInput from "../inputs/SearchInput";
import Greeting from "../layouts/Greeting";
import ServiceWidget from "../layouts/ServiceWidget";
import {
  GetLicensesReport,
  GetLicensesAssigned,
  GetLicenses,
  GetLicensesUsers,
  PostLicense,
  PostAssignLicense,
  PatchLicense,
  deleteLicense,
  getById,
} from "../../utility/fetch";
import Notification from "../../utility/notification";
import { moduleNameData } from "../columns/requestLogColumn";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination, Select } from "antd";
import parse from "html-react-parser";
import CreateLicenseModal from "../modals/CreateLicenseModal";
import MDAsContext from "../../contexts/index";
import AssignModal from "../modals/AssignModal";

const { RangePicker } = DatePicker;
const { Option } = Select;

const { TextArea } = Input;

const Licenses = ({ history }) => {
  const [title, setTitle] = useState("");
  const [receivedMemos, setReceivedMemos] = useState(0);
  const [licenses, setLicenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAssignVisible, setModalAssignVisible] = useState(false);
  const [modalUpdateVisible, setModalUpdateVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [activeMemo, setActiveMemo] = useState("pending");
  const [report, setReport] = useState([]);
  const { mdas } = useContext(MDAsContext);
  const [id, setId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [assignId, setAssignId] = useState("");
  const [reportId, setReportId] = useState();
  const [description, setDescription] = useState("");
  const [moduleCount, setModuleCount] = useState("");

  useEffect(() => {
    fetchLicenses();
    fetchLicensesUsers();
    fetchLicenseReport();
  }, []);

  const fetchLicenseReport = async () => {
    try {
      const result = await GetLicensesReport();
      if (result?.data?.code === 1) {
        setReport(result?.data?.data);
      }
    } catch (ex) {}
  };

  const fetchLicensesUsers = async () => {
    // setLoading(true);
    try {
      const result = await GetLicensesUsers();
      if (result?.data?.code === 1) {
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    // setLoading(false);
  };

  const fetchLicenses = async () => {
    // setLoading(true);
    try {
      const result = await GetLicenses();
      if (result?.data?.code === 1) {
        setLicenses(result?.data?.data);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    // setLoading(false);
  };

  const fetchSingleLicenses = async (id) => {
    // setLoading(true);
    try {
      const result = await getById(id);
      if (result?.data?.code === 1) {
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    // setLoading(false);
  };

  const CreateLicense = async (payload) => {
    setLoading(true);
    try {
      const result = await PostLicense(payload);
      if (result?.data?.code === 1) {
        setModalVisible(false);
        Notification({ type: "success", message: result.data.message });
        fetchLicenses();
        setModuleCount("");
        setDescription("");
        setId("");
      } else  {
        setModalVisible(false);
        Notification({ type: "danger", message: result.data.message });
        fetchLicenses();
        setModuleCount("");
        setDescription("");
        setId("");
      }
    } catch (ex) {
        Notification({ type: "danger", message: ex.Response.message });
    }
    setLoading(false);
  };

  const AssignLicense = async (payload) => {
    setLoading(true);
    try {
      payload.licenseId = assignId;
      const result = await PostAssignLicense(payload);
      if (result?.data?.code === 1) {
        setModalVisible(false);
        Notification({ type: "success", message: result.data.message });
        fetchLicenses();
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  const UpdateLicense = async (payload) => {
    setLoading(true);
    try {
      payload.id = id;
      payload.isActive = true;
      const result = await PatchLicense(payload);
      if (result?.data?.code === 1) {
        setModalUpdateVisible(false);
        Notification({ type: "success", message: result.data.message });
        fetchLicenses();
        setModuleCount("");
        setDescription("");
        setId("");
      } else {
        setModalUpdateVisible(false);
        Notification({ type: "danger", message: result.data.message });
        fetchLicenses();
        setModuleCount("");
        setDescription("");
        setId("");
      }
    } catch (ex) {
      const error = ex.Response;
      Notification({ type: "danger", message: error ?? "An error occured" });
    }
    setLoading(false);
  };

  const RemoveLicense = async () => {
    setLoading(true);
    try {
      const result = await deleteLicense(deleteId);
      if (result?.data?.code === 1) {
        setDeleteModalVisible(false);
        Notification({ type: "success", message: result.data.message });
        fetchLicenses();
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (id) {
  //     setModalUpdateVisible(true);
  //   }
  // }, [id]);

  useEffect(() => {
    if (deleteId) {
      setDeleteModalVisible(true);
    }
  }, [deleteId]);

  const menu = (record) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setId(record.id);
          setDescription(record.description);
          setModuleCount(record.moduleCount);
          setModalUpdateVisible(true);
        }}
      >
        <a rel="noopener noreferrer" href="#">
          Edit
        </a>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setDeleteId(record.id);
        }}
      >
        <a rel="noopener noreferrer" href="#">
          Delete
        </a>
      </Menu.Item>
    </Menu>
  );

  const licensesColums = [
    {
      title: <strong className="family">Description</strong>,
      dataIndex: "description",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Number Of Modules</strong>,
      dataIndex: "moduleCount",
      width: "5%",
      editable: false,
    },
    {
      title: <strong className="family">Created By</strong>,
      dataIndex: "createdByName",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Modified By</strong>,
      dataIndex: "modifiedByName",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Active</strong>,
      render: (item) => (
        <p style={{ cursor: "pointer" }}>{item?.isActive ? "Yes" : "No"}</p>
      ),
      width: "10%",
      align: "center",
    },
    {
      title: <strong className="family">Date Created</strong>,
      dataIndex: "dateCreated",
      width: "15%",
    },
    {
      title: <strong className="family">Action</strong>,
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (text, record) => (
        <Space direction="vertical">
          <Space wrap>
            <Dropdown
              onClick={(event) => event.stopPropagation()}
              overlay={menu(record)}
              trigger={["click"]}
              placement="bottom"
            >
              <Button style={{ backgroundColor: "#1C811C", color: "white" }}>
                Select
              </Button>
            </Dropdown>
          </Space>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Licenses";
    setTitle("Licenses");
  }, []);

  const handleChange = (value) => {};

  return (
    <div className="w-100">
      {loading ? (
        <div>
          <Loader type="ThreeDots" color="green" height={100} width={100} />
        </div>
      ) : (
        <>
          <Greeting />

          <div className="flex space-between m-b-20">
            <div className="flex">
              {/* <div className={`search-input`}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  onChange={handleChange}
                >
                  {mdas.map((item) => (
                    <Option
                      key={item.value}
                      className="customized_input"
                      value={item.value}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div> */}
            </div>

            <Button
              onClick={() => setModalVisible(true)}
              style={{ backgroundColor: "#1C811C", color: "white" }}
            >
              Create License
            </Button>
          </div>
          <div className="flex space-between w-100 primary-color p-15 ">
            <strong className="family">Licenses</strong>
          </div>
          <div className="w-100">
            <Table
              bordered
              pagination={false}
              scroll={{ x: "80vw"}}
              dataSource={licenses}
              columns={licensesColums}
              rowClassName="editable-row"
            />
          </div>

          <ServiceWidget />
        </>
      )}

      <CreateLicenseModal
        title="Create License"
        onFinish={CreateLicense}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        description={description}
        moduleCount={moduleCount}
      />

      <CreateLicenseModal
        title="Update License"
        onFinish={UpdateLicense}
        modalVisible={modalUpdateVisible}
        setModalVisible={setModalUpdateVisible}
        description={description}
        moduleCount={moduleCount}
        setDescription={setDescription}
        setModuleCount={setModuleCount}
      />

      <Modal
        title="Delete License"
        centered
        visible={deleteModalVisible}
        onOk={() => RemoveLicense()}
        onCancel={() => setDeleteModalVisible(false)}
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
    </div>
  );
};

export default Licenses;
