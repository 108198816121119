import Http from "../services/httpService";
import Cookies from "js-cookie";

export const Login = (payload) => {
  return Http.post(`/User/Login`, payload);
};

export const GetLicenses = () => {
  return Http.get(`/Licenses`);
};

export const GetLicensesReport = () => {
  return Http.get(`/Licenses/Report`);
};

export const GetLicensesReportId = (id) => {
  return Http.get(`/Licenses/Report/${id}`);
};

export const GetLicensesAssigned = (id) => {
  return Http.get(`/Licenses/Users/Assigned/${id}`);
};

export const GetLicensesUsers = () => {
  return Http.get(`/Licenses/Users`);
};

export const GetSingleLicensesUsers = (id) => {
  return Http.get(`/Licenses/User/${id}`);
};

export const PostLicense = (payload) => {
  return Http.post(`/Licenses`, payload);
};

export const PostAssignLicense = (payload) => {
  return Http.post(`/Licenses/Assign`, payload);
};

export const PostAssignModule = (payload) => {
  return Http.post(`/Licenses/Assign/Module`, payload);
};

export const PatchLicense = (payload) => {
  return Http.put(`/Licenses`, payload);
};

export const deleteLicense = (id) => {
  return Http.delete(`/Licenses/${id}`);
};

export const getById = (id) => {
  return Http.get(`/Licenses/${id}`);
};

export const GetLicensesSettings = () => {
  return Http.get(`/Licenses/Settings`);
};

export const PostLicenseSettings = (payload) => {
  return Http.post(`/Licenses/Settings`, payload);
};

export const PatchLicenseSettings = (payload) => {
  return Http.put(`/Licenses/Settings`, payload);
};

export const DeleteLicenseSettings = (id) => {
  return Http.delete(`/Licenses/Settings/${id}`);
};

export const DetLicensesSettingsById = (id) => {
  return Http.get(`/Licenses/Settings/${id}`);
};

export const GetModules = () => {
  return Http.get(`/Modules`);
};

export const GetMdas = () => {
  return Http.get(`/MDA`);
};

export const GetMdaUsers = (mdaId) => {
  return Http.get(`/Users/MDA/${mdaId}`);
};

export const GetMdaUsersLicenses = (mdaId) => {
  return Http.get(`/Licenses/MDA/${mdaId}`);
};

export const AssignedModulesPerUser = (id) => {
  return Http.get(`/Licenses/AssignedModulesPerUser/${id}`);
};

export const UpgradeLicence = (payload) => {
  return Http.put(`/Licenses/UpdateUserLicense`, payload);
};
