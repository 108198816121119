import React from "react";
import { Switch, Route } from "react-router";
import { pure } from "recompose";

import ProtectedRoute from "./ProtectedRoute";

import Dashboard from "../components/pages/Dashboard";
import Users from "../components/pages/Users";
import Modules from "../components/pages/Modules";
import Licenses from "../components/pages/Licenses";
import UserModules from "../components/pages/UserModules";
import SessionHOC from "../components/hoc/SessionHOC";

export default pure(() => (
  <Switch>
    <ProtectedRoute
      path="/licensing/"
      exact
      component={SessionHOC(Dashboard)}
    />
    <ProtectedRoute
      path="/licensing/modules"
      exact
      component={SessionHOC(Modules)}
    />
    <ProtectedRoute
      path="/licensing/users"
      exact
      component={SessionHOC(Users)}
    />
    <ProtectedRoute
      path="/licensing/licenses"
      exact
      component={SessionHOC(Licenses)}
    />
    <ProtectedRoute
      path="/licensing/user/modules"
      exact
      component={SessionHOC(UserModules)}
    />

    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
