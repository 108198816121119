import Checkbox from "antd/es/checkbox";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Table from "antd/es/table";
import Button from "antd/es/button";
import React, { useContext, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import MDAsContext from "../../contexts/index";
import {
  AssignedModulesPerUser,
  GetMdaUsers,
  UpgradeLicence,
} from "../../utility/fetch";
import { extractURLQueries } from "../../utility/urlQueryExtractor";
import Notification from "../../utility/notification";

const { Option } = Select;

const { TextArea } = Input;

const UserModules = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const { mdas, modules } = useContext(MDAsContext);
  const [users, setUsers] = useState([]);
  const { id } = extractURLQueries(window.location.search);
  const [userModules, setUserModules] = useState([]);

  const history = useHistory();

  const fetchUserModules = async () => {
    setLoading(true);
    try {
      const result = await AssignedModulesPerUser(id);
      if (result?.data?.code === 1) {
        setUserModules(result?.data?.data?.modules);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - User Modules";
    setTitle("User Modules");
  }, []);

  useEffect(() => {
    fetchUserModules();
  }, []);

  const selectModule = (moduleId) => {
    const copyModule = [...userModules];

    for (let i = 0; i < copyModule.length; i++) {
      if (copyModule[i].moduleId == moduleId) {
        copyModule[i].isAssigned = !copyModule[i].isAssigned;
        break;
      }
    }
    setUserModules(copyModule);
  };

  const usersModulesColumn = [
    {
      title: (
        <div>
          <strong className="family m-r-10">Module</strong>
        </div>
      ),
      dataIndex: "module",
      editable: false,
    },
    {
      title: (
        <div>
          {" "}
          <strong className="family m-r-10">Assigned</strong>
        </div>
      ),
      editable: false,
      width: "10%",
      render: (record) => (
        <Checkbox
          onClick={() => selectModule(record?.moduleId)}
          checked={record?.isAssigned}
        />
      ),
    },
  ];

  const upgradeUserLicense = async () => {

    let modules = userModules.filter((a) => a.isAssigned);
    if(modules.length > 0) {
      setLoading(true);
      modules = modules.map((item) => item.moduleId);

      const payload = {
        userId: Number(id),
        moduleIds: modules,
      };
      try {
        const result = await UpgradeLicence(payload);
        if (result?.data?.code === 1) {
          Notification({ type: "success", message: result.data.message });
        } else {
          Notification({ type: "warning", message: result.data.message });
        }
      } catch (ex) {
        Notification({
          type: "warning",
          message: "Oops!!, Something went wrong",
        });
      }
      fetchUserModules();
      setLoading(false);
    } else {
      Notification({
        type: "warning",
        message: "Oops!!, Please select a module",
      });
    }

  };

  return (
    <div className="w-100">
      {loading ? (
        <div>
          <Loader type="ThreeDots" color="green" height={100} width={100} />
        </div>
      ) : (
        <>
          <div className="flex space-between m-b-20">
            <Button
              onClick={() => upgradeUserLicense(true)}
              style={{ backgroundColor: "#1C811C", color: "white" }}
            >
              Update
            </Button>
          </div>

          <div className="flex space-between w-100 primary-color p-15 ">
            <strong className="family">Users</strong>
          </div>

          <div className="w-100">
            <Table
              bordered
              pagination={false}
              dataSource={userModules}
              columns={usersModulesColumn}
              rowClassName="editable-row"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default UserModules;
